const configTour = {
  // steps: [
  //   {
  //     target: '#v-step-0', // We're using document.querySelector() under the hood
  //     header: {
  //       title: 'Fuente'
  //     },
  //     content: 'Define el tipo de <strong>Fuente</strong> a usar!',
  //     before: () => new Promise((resolve, reject) => {
  //       background1: '#f9f9f9'
  //       resolve()
  //     })
  //   },
  //   {
  //     target: '.v-step-1',
  //     content: 'An awesome plugin made with Vue.js!'
  //   },
  //   {
  //     target: '[data-v-step="2"]',
  //     content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
  //     params: {
  //       placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
  //       enableScrolling: false
  //     }
  //   }
  // ],

  labels: {
    buttonSkip: 'Salir',
    buttonPrevious: 'Atras',
    buttonNext: 'Siguiente',
    buttonStop: 'Finalizar'
  }

}
export default configTour