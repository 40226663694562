<template>
  <div>
    <b-card>
      <div class="row" v-if="Object.keys(form).length !== 0">
        <div class="col-6 col-md-4 col-lg-4 v-step-0-0">
          <label title="Fuente" class="label-form"><span>Fuente</span></label>
          <field-select :value.sync="form.font" :options="fontOptions"/>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-1-0">
          <label title="Fuente" class="label-form"><span>Fondo 1</span></label>
          <color-picker id="background1" :color.sync="form.background1"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-2-0">
          <label title="Fuente" class="label-form"><span>Fondo 2</span></label>
          <color-picker id="background2" :color.sync="form.background2"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-3-0">
          <label title="Fuente" class="label-form"><span>Textos principales</span></label>
          <color-picker id="principal" :color.sync="form.principal"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-4-0">
          <label title="Fuente" class="label-form"><span>Color tracking</span></label>
          <color-picker id="tracking_color" :color.sync="form.tracking_color"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-5-0">
          <label title="Fuente" class="label-form"><span>Texto estados</span></label>
          <color-picker id="status_color" :color.sync="form.status_color"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-6-0">
          <label title="Fuente" class="label-form"><span>Texto sub-estados</span></label>
          <color-picker id="substatus_color" :color.sync="form.substatus_color"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-7-0">
          <label title="Fuente" class="label-form"><span>Texto datos 1</span></label>
          <color-picker id="data1_color" :color.sync="form.data1_color"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 v-step-8-0">
          <label title="Fuente" class="label-form"><span>Texto datos 2</span></label>
          <color-picker id="data2_color" :color.sync="form.data2_color"></color-picker>
        </div>
        <div class="col-6 col-md-4 col-lg-2 offset-lg-2 mt-2 container-info v-step-8-2" style="top: 2px padding: 10px">
          <b-button variant="outline-light" @click="clickSave">Guardar</b-button>
        </div>
      </div>
    </b-card>
    <div class="container-web-tracking">
      <web-tracking :key="keyWebTracking" :styles.sync="form"></web-tracking>
      <div class="btn_guide">
        <b-button class="btn-icon rounded-circle" variant="warning" @click="showGuide"><feather-icon class="m-1" size="2x" icon="HelpCircleIcon" /></b-button>
      </div>
    </div>
    <v-tour name="myTour" :steps="steps" :options="myOptions" :callbacks="myCallbacks"></v-tour>
  </div>
</template>
<script>
import WebTracking from './WebTracking.vue'
import ColorPicker from '@/views/components/custom/color-picker/ColorPicker.vue'
import configTour from './steps'
export default {
  components: { WebTracking, ColorPicker},
  data () {
    return {
      bool: {
        colorTracking: false
      },
      color: '#044389',
      font: {},
      fields: [],
      keyWebTracking: 0,
      form: {
      },
      myCallbacks: {
        onStop: this.loadCustomConfig
      },
      fontOptions: [
        { id: '"Montserrat"', text: 'Montserrat'},
        { id: 'Times new roman', text: 'Times new roman'},
        { id: 'Verdana', text: 'Verdana'},
        { id: 'Arial', text: 'Arial'},
        { id: 'Helvetica', text: 'Helvetica'},
        { id: 'Candara', text: 'Candara'},
        { id: 'Consolas', text: 'Consolas'},
        { id: 'Courier', text: 'Courier'}
      ],
      loadedConfig: null,
      defaultConfig: {
        font: {id: '"Montserrat"', text: 'Montserrat'},
        tracking_color: '#044389',
        status_color: '#6e6b7b',
        substatus_color: '#75797e',
        data1_color: '#6e6b7b',
        data2_color: '#75797e',
        background1: '#F9F9F9',
        background2: '#FFFFFF',
        principal: '#6e6b7b'
      },
      company1: null,
      // steps: configTour.steps,
      myOptions: {
        labels: configTour.labels,
        highlight: true,
        enableScrolling: false
        // debug: true
      },
      steps: []
    }
  },
  watch: {
    form: {
      handler () {
        // this.keyWebTracking++
      },
      deep: true
    }
  },
  mounted () {
    this.configTour()
    this.company1 = this.$route.params.company || this.$route.params.id || '10'
    this.company2 = this.$route.params.company_aux || this.company1
    this.fields = [
      { fieldType: 'FieldSelect', name: 'font', label: 'Fuente', options: this.fontOptions}
    ]
    this.loadFormDefault()
    this.loadedConfig = {...this.defaultConfig}
    this.getPersonalization()
  },
  methods: {

    showGuide () {
      this.$tours['myTour'].start()
    },

    getPersonalization () {
      this.$root.$data.db.collection('webTracking')
        .where(this.$root.$data.fb.firestore.FieldPath.documentId(), 'in', [this.company1, this.company2])//.doc(this.company1)
        .onSnapshot(resp => {
          for (const doc of resp.docs) {
            this.loadedConfig = doc.data() || this.loadedConfig
          }
          this.loadCustomConfig()
        })
    },
    boolColorPicker (name, bool) {
      this.bool[name] = bool
    },
    clickSave () {
      const payload = {}
      Object.keys(this.form).map(key => {
        payload[key] = this.form[key]?.id || this.form[key]
      })
      const companies = [...new Set([this.company1, this.company2])]
      this.savePersonalization(payload, companies)
    },
    savePersonalization (payload, companies) {
      const batch = this.$root.$data.db.batch()
      companies.forEach((doc) => {
        const docRef = this.$root.$data.db.collection('webTracking').doc(`${doc}`) //automatically generate unique id
        batch.set(docRef, payload)
      })
      batch.commit()
        .then(resp => {
          this.$success(this.$t('msg-exito-guardar'))
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-guardar'))
        })

      // this.$root.$data.db.collection('webTracking')
      //   .where(this.$root.$data.fb.firestore.FieldPath.documentId(), 'in', [this.company1, this.company2])
      //   .set(payload)
      //   .then(resp => {
      //     this.$success(this.$t('msg-exito-guardar'))
      //   })
      //   .catch(err => {
      //     console.error(err)
      //     this.$alert(this.$t('msg-problema-guardar'))
      //   })
    },
    loadFormDefault () {
      this.form = {...this.defaultConfig}
    },
    loadCustomConfig () {
      this.form = {...this.form, ...this.loadedConfig}
    },
    configTour () {
      this.steps = [
        
        {
          target: '.v-step-0-0', // We're using document.querySelector() under the hood
          header: {
            title: '<strong>Fuente</strong>'
          },
          content: 'Define el tipo de fuente a usar!',
          params: { 
            placement: 'bottom',
            background: '#6E6B7B'
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
          })
        },
        {
          target: '.v-step-0-1',
          content: 'Lo verás aplicado en todas las fuentes del web tracking',
          params: { 
            placement: 'left',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.font = { id: 'Times new roman', text: 'Times new roman'}
            resolve()
          })
        },
        {
          target: '.v-step-1-0',
          header: {
            title: '<strong>Color de fondo</strong>'
          },
          content: 'Acá defines el color que fondo de tu personalización',
          params: { 
            placement: 'bottom'
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
          })
        },
        {
          target: '.v-step-1-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.background1 = '#FBD7D2'
            resolve()
          })
        },
        {
          target: '.v-step-2-0',
          header: {
            title: '<strong>Color de fondo card</strong>'
          },
          content: 'Acá defines el color que fondo de la card de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
          })
        },
        {
          target: '.v-step-2-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.background2 = '#FBD7D2'
            resolve()
          })
        },
        {
          target: '.v-step-3-0',
          header: {
            title: '<strong>Color de textos principales</strong>'
          },
          content: 'Acá defines el color que tendrán los textos principales de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-3-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.principal = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-4-0',
          header: {
            title: '<strong>Color de linea de seguimiento</strong>'
          },
          content: 'Acá defines el color que tendrán la Linea de seguiento de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-4-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'top',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.tracking_color = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-5-0',
          header: {
            title: '<strong>Color de textos de estados</strong>'
          },
          content: 'Acá defines el color que tendrán los textos de estados de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-5-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'left',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.status_color = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-6-0',
          header: {
            title: '<strong>Color de textos de sub-estados</strong>'
          },
          content: 'Acá defines el color que tendrán los textos de sub-estados de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-6-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.substatus_color = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-7-0',
          header: {
            title: '<strong>Color de textos de datos</strong>'
           
          },
          content: 'Acá defines el color que tendrán los textos de datos de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-7-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.data1_color = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-8-0',
          header: {
            title: '<strong>Color de textos de datos</strong>'
          },
          content: 'Acá defines el color que tendrán los textos de datos de tu personalización',
          params: {
            placement: 'bottom', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve()
            // reject(this.form.background1 = '#8B572A')
          })
        },
        {
          target: '.v-step-8-1',
          content: 'Se verá modificado de esta manera',
          params: { 
            placement: 'bottom',
            highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.form.data2_color = '#D0021B'
            resolve()
          })
        },
        {
          target: '.v-step-8-2',
          header: {
            title: '<strong>Guardar Datos</strong>'
          },
          content: 'Guarda para preservar customización de toda la vista <strong>Web tracking</strong>!!',
          params: { 
            placement: 'bottom'
            // highlight: false
          },
          before: () => new Promise((resolve, reject) => {
            this.loadFormDefault()
            resolve(this.loadCustomConfig)
            reject(this.loadCustomConfig)
          })
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.container-web-tracking{
  border: 1px solid #ebe9f1;
  border-radius: 5px;
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}

.v-tour__target--highlighted {
  border-radius: 5px;
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  pointer-events: auto;
  z-index: 9999;
}

.btn_guide {
  position: fixed;
  bottom: 20px;
  left: 50px;
  z-index: 1031;
  .btn {
    box-shadow: 0 1px 20px 1px #f18805 !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.v-step {
  background: #ffffff !important;
  border: 1px solid #82868b !important; 
  border-radius: 10px !important;
}

.v-step__header {
    display: flex !important;
    text-align: left !important;
    margin: -1rem -1rem 0.5rem;
    padding: 0.5rem;
    color: #82868b !important;
    background-color: #F9F9F9 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.v-step__content {
  color: #82868b !important;
}

.v-step__button {
  color: #4a7bbe !important;
  border: .05rem solid #4a7bbe !important;
  border-radius: .3rem !important;
  &:hover {
      background-color: rgba(#4a7bbe, 0.95) !important;
      color: #ffffff !important;
    }
}

</style>